.g66FTq_drawerModal {
  background-color: #00000080;
  display: flex;
  position: fixed;
  inset: 0;
}

.g66FTq_drawerBody {
  width: 40em;
  color: #fff;
  background-color: #5f9ea0;
}

.g66FTq_drawerContent {
  padding: 1.5em 0;
}

.g66FTq_drawerTop {
  max-height: 5em;
  min-height: 2em;
  justify-content: right;
  align-items: center;
  padding-left: 1em;
  display: flex;
}

.g66FTq_drawerCloseButton {
  height: 100%;
}

.g66FTq_drawerCloseButton > button {
  height: 100%;
  cursor: pointer;
  background-color: #5f9ea0;
  border: 0;
  outline: 0;
}

.g66FTq_drawerCloseButton > button > svg {
  width: 6em;
  fill: #fff;
}

.g66FTq_drawerContentLink {
  height: 3em;
  flex-flow: column;
  display: flex;
}

.g66FTq_drawerContentLink > button {
  height: inherit;
  text-align: start;
  background-color: inherit;
  color: inherit;
  cursor: pointer;
  border: 0;
  outline: 0;
  padding-left: 1em;
  font-size: 2em;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

.fJJtSW_gameName {
  text-align: center;
}

.fJJtSW_scoreGrid {
  flex-direction: row;
  justify-content: space-around;
  display: flex;
}

.fJJtSW_scoreList {
  flex-direction: column;
  display: flex;
}

.fJJtSW_scoreValue {
  color: #fff;
  background-color: #5f9ea0;
  font-size: 1.5em;
}

.fJJtSW_scoreValue:focus {
  background-color: #b8860b;
}

.fJJtSW_padRight {
  padding-right: 1em;
}

.fJJtSW_playerName {
  text-align: center;
  color: wheat;
  background-color: #5f9ea0;
  font-size: 1.5em;
}

.fJJtSW_playerNamePadding {
  padding-bottom: 1em;
}

.fJJtSW_playerTotal {
  text-align: center;
  color: wheat;
  background-color: #5f9ea0;
  font-size: 1.5em;
}

.fJJtSW_playerTotalPadding {
  padding-top: 1em;
}

.fJJtSW_badScore, .fJJtSW_badScore:focus {
  background-color: maroon;
}

.-xwNfa_appBody {
  height: 100vh;
  color: wheat;
  box-sizing: border-box;
  background-color: #000;
  flex-direction: column;
  display: flex;
}

.-xwNfa_appHeaderBar {
  height: 100%;
  max-height: 5em;
  min-height: 2em;
  background-color: #5f9ea0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.-xwNfa_title {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.-xwNfa_title > p {
  color: #fff;
  letter-spacing: .00938em;
  cursor: default;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 2em;
  font-weight: 400;
  line-height: 1.5;
}

.-xwNfa_leftMenuButton {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.-xwNfa_leftMenuButton > button {
  height: 100%;
  cursor: pointer;
  background-color: #5f9ea0;
  border: 0;
  outline: 0;
}

.-xwNfa_leftMenuButton > button > svg {
  width: 6em;
  fill: #fff;
}

.-xwNfa_appContent {
  min-height: 0;
  width: 100%;
  flex: auto;
  justify-content: center;
  display: flex;
  overflow-y: auto;
}

.-xwNfa_setupDropdownContainer {
  flex-direction: column;
  display: flex;
}

.-xwNfa_setupLabel {
  padding-bottom: 1em;
  font-size: 2em;
}

.-xwNfa_setupSelect {
  font-size: 1.5em;
}

/*# sourceMappingURL=index.248c0b06.css.map */
